import React, { useState, useEffect } from 'react';
import { Textarea } from 'react-bulma-components/lib/components/form';

function TextAreaElement({ id, name, value, placeholder, callback }) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    if (callback) {
      callback(name, event.target.value);
    }
  };
  return <Textarea id={id} name={name} value={currentValue} placeholder={placeholder} onChange={handleChange} />;
}

export default TextAreaElement;
