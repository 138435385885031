import React from 'react';
import './_intro.scss';

function Intro() {
  return (
    <div className='intro'>
      <h1>Welcome to Artificial Researcher’s Passage Retrieval Service</h1>
      <p>
        On this platform we provide a demo version of our unique Passage Retrieval Service. Our service uses innovative text mining technologies based on 15 years of collected know-how and research that gives you state of the art machine learning. The data collections available to you in this demo
        are: COVID-19 Open Research Dataset (CORD-19) data set, a sample set from the EP Full-Text Data for Text Analytics, and a sample composed of different scientific publications within technical and medical science provided by CORE.uk (Science), (<a href="/files/AR-PassageRetrievalService.pdf" target="_blank">White Paper</a>). Please give us feedback and let us know what you
        think to help us improve and develop a service you will love to use. For access our larger collections, which we provide via APIs, please contact <a href='mailto:demo@artificialresearcher.com'>demo@artificialresearcher.com</a>
      </p>
      <p>Artificial Researcher – IT GmbH</p>
    </div>
  );
}

export default Intro;
