import React, { useState } from 'react';
// eslint-disable-next-line no-unused-vars
import Modal from 'react-bulma-components/lib/components/modal';
import Columns from 'react-bulma-components/lib/components/columns';
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import SelectElement from '../input/selectElement';
import TextAreaElement from '../input/textAreaElement';
import { FormDataToJSON } from '../../utils/formToJSON';
import { SendLog } from '../../utils/log';
import './_modal.scss';

export default function ModalReport({ show, onClose, data, ...rest }) {
  const [done, setDone] = useState(false);

  const submit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const select = form.querySelector('select');
    const textarea = form.querySelector('textarea');
    select.parentNode.classList.remove('is-danger');
    textarea.classList.remove('is-danger');
    if (select.value.length === 0) {
      select.parentNode.classList.add('is-danger');
    }
    if (textarea.value.length === 0) {
      textarea.classList.add('is-danger');
    }
    if (select.value.length === 0 || textarea.value.length === 0) {
      return;
    }
    const submit = form.querySelector('button[type="submit"]');
    submit.setAttribute('disabled', 'disabled');
    submit.classList.add('is-loading');
    const opt = {
      action: 'report',
      data: FormDataToJSON(form),
    };
    SendLog(opt)
      .then((data) => {
        console.log('Data from SendLog() with async( When promise gets resolved ): ' + data);
        setDone(true);
        submit.removeAttribute('disabled');
        submit.classList.remove('is-loading');
      })
      .catch((error) => {
        console.log('Error from SendLog() with async( When promise gets rejected ): ' + error);
      });
  };
  const close = () => {
    setDone(false);
    onClose();
  };
  return (
    <div className={'modal' + (show === true ? ' is-active' : '')}>
      <div className="modal-background" onClick={onClose} />
      <div className="modal-card">
        <form onSubmit={submit}>
          <header className="modal-card-head">
            <p className="modal-card-title">Report data error</p>
            <button className="delete" type="button" onClick={onClose} />
          </header>
          <section className="modal-card-body">
            <div className="content">
              {done && <p>Thank you for helping us improve our service.</p>}
              {!done && (
                <>
                  <p>Fill in the form and press send to submit the error.</p>
                  <div>
                    <input type="hidden" name="Id" value={data.Id} />
                    {/* <input type="hidden" name="Title" value={data.Title} />
                    <input type="hidden" name="TextParagraph" value={data.TextParagraph} />
                    <input type="hidden" name="Keywords" value={data.Keywords} />
                    <input type="hidden" name="Link" value={data.Link} />
                    <input type="hidden" name="PublicationDate" value={data.PublicationDate} />
                    <input type="hidden" name="Authors" value={data.Authors} />
                    <input type="hidden" name="Taxonomy" value={data.Taxonomy} />
                    <input type="hidden" name="Abstract" value={data.Abstract} /> */}
                    <Columns className="">
                      <div className="column is-full has-heading">
                        <Heading renderAs={'h2'} size={5}>
                          Section
                        </Heading>
                      </div>
                      <div className="column is-full">
                        <SelectElement
                          name={'Section'}
                          values={[
                            { value: '', text: 'Select' },
                            { value: 'Title', text: 'Title' },
                            { value: 'TextParagraph', text: 'Text' },
                            { value: 'Keywords', text: 'Keywords' },
                            { value: 'Link', text: 'Link' },
                            { value: 'PublicationDate', text: 'Publication Date' },
                            { value: 'Authors', text: 'Authors' },
                            { value: 'Taxonomy', text: 'Taxonomy' },
                            { value: 'Abstract', text: 'Abstract' },
                          ]}
                        />
                      </div>
                    </Columns>
                    <Columns className="">
                      <div className="column is-full has-heading">
                        <Heading renderAs={'h2'} size={5}>
                          Explain the error
                        </Heading>
                      </div>
                      <div className="column is-full">
                        <TextAreaElement name={'Error'} placeholder={''} />
                      </div>
                    </Columns>
                  </div>
                </>
              )}
            </div>
          </section>
          <footer className="modal-card-foot">
            {!done && (
              <Button.Group position={'right'}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color={'info'} type="submit">
                  Send report
                </Button>
              </Button.Group>
            )}

            {done && (
              <Button.Group position={'right'}>
                <Button color={'info'} onClick={close}>
                  Close
                </Button>
              </Button.Group>
            )}
          </footer>
        </form>
      </div>
    </div>
  );
}
