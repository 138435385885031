import React from 'react';
import {
  HashRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';
import SearchPage from './js/pages/SearchPage';
import ResultsPage from './js/pages/ResultsPage';
import NotFoundPage from './js/pages/NotFoundPage';
import './scss/styles.scss';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/'>
          <SearchPage />
        </Route>
        <Route exact path='/search'>
          <SearchPage />
        </Route>
        <Route exact path='/results'>
          <ResultsPage />
        </Route>

        <Route path='/404'>
          <NotFoundPage />
        </Route>
        <Redirect to='/404' />
      </Switch>
      <ToastContainer transition={Slide} />
    </Router>
  );
}

export default App;
