import React from 'react';
import SearchForm from './SearchForm';
import SearchHeader from './SearchHeader';
// import SearchFooter from './SearchFooter';

function Search() {
  return (
    <div className="search">
      <SearchHeader />
      <div className="">
        <SearchForm />
      </div>
      {/* <SearchFooter /> */}
    </div>
  );
}

export default Search;
