import React from 'react';
import './_loading.scss';

function Loading() {
  return (
    <div className="loading">
      <div className="loading__indicator"></div>
    </div>
  );
}

export default Loading;
