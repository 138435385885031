import React from 'react';
import Icon from 'react-bulma-components/lib/components/icon';
import './_feedback.scss';

function Feedback({ keyword, feedback }) {
  return (
    <div className="feedback">
      <button
        type="button"
        style={{ transform: 'rotate(180deg)' }}
        onClick={(e) => {
          feedback(e, true, keyword);
        }}
      >
        <Icon icon="angle-down" color="success" />
      </button>
      <button
        type="button"
        onClick={(e) => {
          feedback(e, false, keyword);
        }}
      >
        <Icon icon="angle-down" color="danger" />
      </button>
    </div>
  );
}

export default Feedback;
