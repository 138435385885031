import React, { useState, useEffect } from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import Results from '../components/results/Results';
import Data from '../data/example_output_0.json';

function ResultsPage() {
  const [search] = useState(
    window.location.hash.substring(window.location.hash.indexOf('?'))
  );
  const [data, setData] = useState([]);
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      fetch(
        'https://passageretrieval-api.artificialresearcher.com/search' +
          search +
          '&simpleAuth=A34G48GJKEO9Y95IR3E96JDFI3I7O9E323189CJ573'
      )
        .then((res) => res.json())
        .then(
          (result) => {
            setData(result.response);
          },
          (error) => {
            console.error(error);
          }
        );
    } else {
      setData(Data.response);
    }

    // setData(Data);
    // }, [data]);
  }, [search]);
  return (
    <Section>
      <Container>
        <Results data={data} />
      </Container>
    </Section>
  );
}

export default ResultsPage;
