import React, { useState } from 'react';
import { Label } from 'react-bulma-components/lib/components/form';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './_input-date-picker.scss';

export default function InputDatePicker(props) {
  // const [startDate, setStartDate] = useState(props.value);
  const [currentValue, setCurrentValue] = useState(props.value);

  const handleChange = (date) => {
    setCurrentValue(date);
    if (props.callback) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      props.callback(props.name, year + '-' + month + '-' + day);
    }
  };

  return (
    <div>
      {props.label && <Label htmlFor={props.id}>{props.label}</Label>}
      <DatePicker id={props.id} className="input" name={props.name} selected={currentValue} minDate={props.minDate} maxDate={props.maxDate} dateFormat="yyyy-MM-dd" placeholderText="2020-01-01" onChange={(date) => handleChange(date)} autoComplete="off" />
    </div>
  );
}
