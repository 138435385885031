import React, {useRef } from 'react';
import './_info.scss';

export default function Info({ isShown, children }) {
  const containerRef = useRef(null);
  // useEffect(() => {
  //   if (isShown && containerRef.current.getBoundingClientRect().top < 0) {
  //     let value = Math.ceil(20 + containerRef.current.getBoundingClientRect().top - 10);
  //     containerRef.current.style.bottom = `calc(100% + ${value}px)`;
  //   } else {
  //     containerRef.current.style.bottom = `calc(100% + 20px)`;
  //   }
  // });
  return (
    <div className={'info' + (isShown ? '' : ' is-hidden')} ref={containerRef}>
      {children}
      {/* <div className='info__arrow'></div> */}
    </div>
  );
}
