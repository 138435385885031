import React from 'react';
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';

import Search from '../components/search/Search';

function SearchPage() {
  return (
    <Section>
      <Container>
        <Search />
      </Container>
    </Section>
  );
}

export default SearchPage;
