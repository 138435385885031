/*eslint no-loop-func: 0*/
import React, { useState, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Columns from 'react-bulma-components/lib/components/columns';
import Button from 'react-bulma-components/lib/components/button';
import Pagination from 'react-bulma-components/lib/components/pagination';
import { SendLog } from '../../utils/log';
import ResultsList from './ResultsList';
import ResultsHeader from './ResultsHeader';
import './_header.scss';
import './_footer.scss';
import './_results.scss';

export default function Results({ data }) {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [items] = useState(5);
  const [currentData, setCurrentData] = useState([]);
  const [currentDocuments, setCurrentDocuments] = useState(null);
  const [currentParagraphs, setCurrentParagraphs] = useState(null);
  useEffect(() => {
    if (data !== null) {
      const tempData = cloneDeep(data);
      const page = 1;
      const start = (page - 1) * items;
      const end = page * items;
      let count = 0;
      let total = 0;
      let documents = tempData.length !== 0 ? tempData.length : null;
      for (let i = 0; i < tempData.length; i++) {
        tempData[i].Paragraphs = tempData[i].Paragraphs.filter((item) => {
          total++;
          if (count >= start && count < end) {
            count = count + 1;
            return item;
          } else if (count < start) {
            count = count + 1;
          }
          return null;
        });
      }
      setCurrentData(tempData);
      setPages(Math.ceil(total / items));
      setCurrentDocuments(documents);
      setCurrentParagraphs(total);
    } else {
      setCurrentData(null);
    }
  }, [data, items]);

  const goToPage = (event) => {
    // console.log(event);
    setPage(event);
    paginate(data, event);
    document.querySelector('.results').scrollIntoView({ behavior: 'smooth' });
  };

  const paginate = (data, page) => {
    const tempData = cloneDeep(data);
    const start = (page - 1) * items;
    const end = page * items;
    let count = 0;
    for (let i = 0; i < tempData.length; i++) {
      tempData[i].Paragraphs = tempData[i].Paragraphs.filter((item) => {
        if (count >= start && count < end) {
          count = count + 1;
          return item;
        } else if (count < start) {
          count = count + 1;
        }
        return null;
      });
    }
    setCurrentData(tempData);
  };

  const toggleItem = (element) => {
    // console.log(element);
    // console.log(element.checked);
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].Paragraphs.length; j++) {
        const paragraph = data[i].Paragraphs[j];
        if (paragraph.Id === element.getAttribute('value')) {
          paragraph.Selected = element.checked;
          // console.log(paragraph);
        }
      }
    }
    // console.log(data);
  };

  const download = () => {
    const selected = document.querySelectorAll('[name="selected"]:checked ');
    if (selected) {
      const results = {
        Timestamp: new Date(),
        Paragraphs: [],
      };
      const paragraphsWithId = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        for (let j = 0; j < item.Paragraphs.length; j++) {
          const paragraph = item.Paragraphs[j];
          if (paragraph.Selected === true) {
            const obj = {
              Title: item.Title,
              Year: new Date(item.PublicationDate).getFullYear(),
              Authors: item.Authors,
              Affiliation: item.Affiliation,
              Link: item.Link,
              Abstract: item.Abstract,
              Keyword: paragraph.KeyWordInContext,
              Taxonomy: item.Taxonomy,
              Paragraph: paragraph.TextParagraph,
            };
            const objWithId = {
              Id: paragraph.Id,
              Title: item.Title,
              Year: new Date(item.PublicationDate).getFullYear(),
              Authors: item.Authors,
              Affiliation: item.Affiliation,
              Link: item.Link,
              Abstract: item.Abstract,
              Keyword: paragraph.KeyWordInContext,
              Taxonomy: item.Taxonomy,
              Paragraph: paragraph.TextParagraph,
            };
            results.Paragraphs.push(obj);
            paragraphsWithId.push(objWithId);
          }
        }
      }
      // console.log(results);
      downloadObjectAsJson(results, 'results');

      const opt = {
        action: 'download',
        data: paragraphsWithId,
      };
      SendLog(opt)
        .then((data) => {
          console.log('Data from SendLog() with async( When promise gets resolved ): ' + data);
        })
        .catch((error) => {
          console.log('Error from SendLog() with async( When promise gets rejected ): ' + error);
        });
    }

    function downloadObjectAsJson(exportObj, exportName) {
      var dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj));
      var downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', exportName + '.json');
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
  };
  return (
    <div className='results'>
      <ResultsHeader />
      {currentDocuments && (
        <div>
          <div className='results__summary'>
            The search returned {currentDocuments} documents with {currentParagraphs} paragraphs
          </div>
          <div className=''>
            We offer a more extensive Passage Retrieval Service, to learn more contact us at <a href='mailto:demo@artificialresearcher.com'>demo@artificialresearcher.com</a>. Our offers include an API as well as deployment of a local host service.
          </div>
        </div>
      )}
      <ResultsList data={currentData} toggleItem={toggleItem} />
      <footer>
        {data !== null && typeof data === 'object' && data.length > 0 && (
          <Columns className='columns'>
            <div className='column'></div>
            <div className='column'>
              <Pagination className={'is-centered'} current={page} total={pages} delta={2} showPrevNext={false} onChange={goToPage} />
            </div>
            <div className='column'>
              <Button color={'dark'} onClick={download}>
                Download
              </Button>
            </div>
          </Columns>
        )}
      </footer>
    </div>
  );
}
