import React, { useState, useEffect } from 'react';
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import { SendLog } from '../../utils/log';
import Info from '../info/Info';
import ModalReport from '../modal/modalReport';
import Keywords from '../keywords/Keywords';
import Items from './Items';
import InputCheckbox from '../input/inputCheckbox';
import Icon from 'react-bulma-components/lib/components/icon';

export default function Resitm({ item, paragraph, toggleItem }) {
  const [isShown, setIsShown] = useState(false);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});

  useEffect(() => {
    setData({ Id: paragraph.Id, Title: item.Title, TextParagraph: paragraph.TextParagraph, Keywords: paragraph.KeyWordInContext, Link: item.Link, PublicationDate: item.PublicationDate, Authors: item.Authors, Taxonomy: item.Taxonomy, Abstract: item.Abstract });
  }, [item, paragraph]);
  const open = () => {
    console.log(data);
    setShow(true);
  };
  const close = () => {
    console.log('asdasd');
    setShow(false);
  };
  const moreInfo = () => {
    if (!isShown) {
      const opt = {
        action: 'more info',
        data: {
          Id: data.Id,
        },
      };
      SendLog(opt)
        .then((data) => {
          console.log('Data from SendLog() with async( When promise gets resolved ): ' + data);
        })
        .catch((error) => {
          console.log('Error from SendLog() with async( When promise gets rejected ): ' + error);
        });
    }
    setIsShown(!isShown);
  };
  return (
    <Box>
      <article className="resitm">
        <div className="resitm__content">
          <h1 className="resitm__title">{item.Title}</h1>
          <p className="resitm__paragraph">{paragraph.TextParagraph}</p>
          {paragraph.KeyWordInContext && <Keywords paragraph={paragraph} data={data} />}
          <strong>Link:</strong>{' '}
          <a className="resitm__link" href={item.Link} target="_blank" rel="noopener noreferrer">
            {item.Link}
          </a>
          <div className="resitm__info">
            <button className="resitm__info-action" onClick={moreInfo} aria-expanded={isShown}>
              More info
              <Icon icon="angle-down" />
            </button>
            <Info isShown={isShown}>
              <div className="">
                {item.PublicationDate && (
                  <div>
                    <strong>Year:</strong> {new Date(item.PublicationDate).getFullYear()}
                  </div>
                )}
                {item.Authors && <Items text={'Authors'} list={item.Authors} />}
                {item.Taxonomy && <Items text={'Taxonomy'} list={item.Taxonomy} />}
                {item.Affiliation && <Items text={'Affiliation'} list={item.Affiliation} />}
                {item.Abstract && (
                  <div>
                    <strong>Abstract:</strong> {item.Abstract}
                  </div>
                )}
              </div>
            </Info>
          </div>
        </div>
        <div className="resitm__select">
          <InputCheckbox label={'Select'} id={paragraph.Id} name={'selected'} value={paragraph.Id} onChange={toggleItem} />
        </div>
        <div className="resitm__report">
          <Button onClick={open}>Report data error</Button>
          <ModalReport show={show} onClose={close} data={{ Id: paragraph.Id, Title: item.Title, TextParagraph: paragraph.TextParagraph, Keywords: paragraph.KeyWordInContext, Link: item.Link, PublicationDate: item.PublicationDate, Authors: item.Authors, Taxonomy: item.Taxonomy, Abstract: item.Abstract }} />
        </div>
      </article>
    </Box>
  );
}
