import React from 'react';
import { Link } from 'react-router-dom';
import Section from 'react-bulma-components/lib/components/section';
import Container from 'react-bulma-components/lib/components/container';
import SearchHeader from '../components/search/SearchHeader';

function NotFoundPage() {
  return (
    <Section>
      <Container>
        <div className='search'>
          <SearchHeader />
          <div className=''>
            This page doesn't exist. Go back to <Link to='/'>the start page.</Link>
          </div>
        </div>
      </Container>
    </Section>
  );
}

export default NotFoundPage;
