import React, { useState, useEffect } from 'react';
import { Label } from 'react-bulma-components/lib/components/form';
import './_input-checkbox.scss';
function InputCheckbox({ label, id, name, value, onChange }) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  function handleInputChange(event) {
    setCurrentValue(event.target.checked);
    if (onChange) onChange(event.target);
  }

  return (
    <div className="control">
      {label && (
        <Label htmlFor={id}>
          <input type="checkbox" id={id} name={name} value={currentValue} onChange={handleInputChange} />
          {label}
        </Label>
      )}
    </div>
  );
}

export default InputCheckbox;
