import React, { useState, useEffect } from 'react';
import { Select } from 'react-bulma-components/lib/components/form';

function SelectElement({ id, name, value, values, callback }) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    // if (callback) {
    //   callback(name, event.target.value);
    // }
  };

  return (
    <Select id={id} name={name} value={currentValue} onChange={handleChange}>
      {values &&
        values.map((item, index) => {
          return (
            <option value={item.value} key={index}>
              {item.text}
            </option>
          );
        })}
    </Select>
  );
}

export default SelectElement;
