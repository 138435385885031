import React from 'react';
import { SendLog } from '../../utils/log';
import { toast } from 'react-toastify';
import Feedback from '../feedback/Feedback';
import 'react-toastify/dist/ReactToastify.css';
import './_keywords.scss';

function Keywords({ paragraph, data }) {
  const feedback = (e, positive, keyword) => {
    if (e.currentTarget.disabled) {
      return false;
    }
    e.currentTarget.setAttribute('disabled', 'disabled');
    const opt = {
      action: positive ? 'positive' : 'negative',
      data: {
        Id: data.Id,
        TextParagraph: data.TextParagraph,
        Keywords: data.Keywords,
        Taxonomy: data.Taxonomy,
        Selected: keyword,
      },
    };
    toast.info('Thank you for your feedback!');
    SendLog(opt)
      .then((data) => {
        console.log('Data from SendLog() with async( When promise gets resolved ): ' + data);
      })
      .catch((error) => {
        console.log('Error from SendLog() with async( When promise gets rejected ): ' + error);
      });
  };
  return (
    <div className="keywords">
      <span>
        <strong>Keywords: </strong>
      </span>
      {paragraph.KeyWordInContext.map((word, index) => {
        return (
          <span className="keyword" key={index}>
            <Feedback keyword={word} feedback={feedback} />
            {paragraph.KeyWordInContext.length - 1 === index ? <span key={index}> {word}</span> : <span key={index}>{word + ','}&nbsp;</span>}
          </span>
        );
      })}
    </div>
  );
}

export default Keywords;
