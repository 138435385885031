import React from 'react';

function Items({ text, list }) {
  return (
    <div>
      <strong>{text}: </strong>
      {list.map((item, index) => {
        return list.length - 1 === index ? <span key={index}>{item.trim()}</span> : <span key={index}>{item.trim() + ','}&nbsp;</span>;
      })}
    </div>
  );
}

export default Items;
