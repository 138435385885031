import React, { useState, useEffect } from 'react';
import { Label, Input } from 'react-bulma-components/lib/components/form';

export default function InputText({ label, id, name, value, placeholder, callback }) {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    // if (callback) {
    //   callback(name, event.target.value);
    // }
  };

  return (
    <>
      {label && <Label htmlFor={id}>{label}</Label>}
      <Input id={id} type="text" name={name} value={currentValue} placeholder={placeholder} onChange={handleChange} />
    </>
  );
}
