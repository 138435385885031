import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import serialize from 'form-serialize';
import Columns from 'react-bulma-components/lib/components/columns';
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import InputText from '../input/inputText';
import InputDatePicker from '../input/inputDatePicker';
import InputCheckbox from '../input/inputCheckbox';
import SelectElement from '../input/selectElement';
import TextAreaElement from '../input/textAreaElement';

function SearchForm() {
  const [search] = useState(window.location.hash.substring(window.location.hash.indexOf('?')));
  const [loading, setLoading] = useState(false);
  const [userDefinedSearch, setUserDefinedSearch] = useState(false);
  const [values, setValues] = useState({});

  const history = useHistory();

  useEffect(() => {
    if (search) {
      function queryStringToJSON(qs) {
        qs = qs || window.location.hash.substring(window.location.hash.indexOf('?')).slice(1);
        var pairs = qs.split('&');
        var result = {};
        pairs.forEach(function (p) {
          var pair = p.split('=');
          var key = pair[0];
          var value = decodeURIComponent(pair[1] || '');
          if (result[key]) {
            if (
              Object.prototype.toString.call(result[key]) === '[object Array]'
            ) {
              result[key].push(value);
            } else {
              result[key] = [result[key], value];
            }
          } else {
            result[key] = value;
          }
        });

        return JSON.parse(JSON.stringify(result));
      }
      var obj = queryStringToJSON();
      setValues(obj);
    }
  }, [search]);

  // function clear(event) {
  //   event.preventDefault();
  //   console.log('asd');
  //   setFormValues('gd');
  // }

  function updateValues(name, value) {
    const obj = values;
    obj[name] = value;
    setValues(obj);
  }

  function submit(event) {
    event.preventDefault();
    const form = document.querySelector('form');
    const query = form.querySelector('[name="query"]');
    const domain = form.querySelector('[name="domain"]');
    const pdyearFrom = form.querySelector('[name="pdyearFrom"]');
    const pdyearTo = form.querySelector('[name="pdyearTo"]');
    const userDefinedSearch = form.querySelector(
      'input[type="checkbox"][name="userDefined"]'
    );
    query.classList.remove('is-danger');
    domain.parentNode.classList.remove('is-danger');

    if (query.value.length === 0) {
      query.classList.add('is-danger');
    }
    if (domain.value.length === 0) {
      domain.parentNode.classList.add('is-danger');
    }
    if (query.value.length === 0 || domain.value.length === 0) {
      return;
    }
    let dateFiller = '';
    if (pdyearFrom.value.length === 0) {
      dateFiller += '&pdyearFrom=1900-01-01';
    }
    if (pdyearTo.value.length === 0) {
      const now = new Date();
      const year = now.getFullYear();
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const pdyearTo = year + '-' + month + '-' + day;
      dateFiller += '&pdyearTo=' + pdyearTo;
    }
    if (userDefinedSearch.checked) {
      form
        .querySelector('input[type="hidden"][name="userDefined"]')
        .setAttribute('disabled', 'disabled');
      query.value = '(' + query.value + ')';
    } else {
      form
        .querySelector('input[type="hidden"][name="userDefined"]')
        .removeAttribute('disabled');
    }
    setLoading(true);
    let search = serialize(form) + dateFiller;
    search = search.replace(/\+/g, '%20');
    history.push('/results?' + search);
  }
  function booleanChange(e) {
    setUserDefinedSearch(e.checked);
  }
  return (
    <form className='mt-10' action='' method='POST'>
      {/* <input type="hidden" name="userDefined" value="false" /> */}
      <Columns className=''>
        <div className='column is-full is-offset-2 has-heading'>
          <Heading renderAs={'h2'} size={5}>
            Select collection*
          </Heading>
        </div>
        <div className='column is-one-third is-offset-2'>
          <SelectElement
            name={'domain'}
            values={[
              { value: '', text: 'Select' },
              { value: 'patent', text: 'Patent' },
              { value: 'covid', text: 'Covid-19' },
              { value: 'science', text: 'Science' },
            ]}
            value={values.domain}
          />
        </div>
      </Columns>
      <Columns className=''>
        <div className='column is-full is-offset-2 has-heading'>
          <Heading renderAs={'h2'} size={5}>
            Meta data
          </Heading>
        </div>
        <div className='column is-one-third is-offset-2'>
          <InputText
            label={'Name'}
            id={'authors'}
            name={'authors'}
            value={values.authors}
            placeholder={'Miller OR Zhang'}
            callback={updateValues}
          />
        </div>
        <div className='column is-one-third'>
          <InputText
            label={'Affiliation'}
            id={'affiliation'}
            name={'affiliation'}
            value={values.affiliation}
            placeholder={'Johns Hopkins University School of Medicine'}
            callback={updateValues}
          />
        </div>
      </Columns>
      <Columns className=''>
        <div className='column is-one-third is-offset-2'>
          <InputDatePicker
            label={'Date from'}
            id={'pdyearFrom'}
            name={'pdyearFrom'}
            value={values.pdyearFrom}
            callback={updateValues}
          />
        </div>
        <div className='column is-one-third'>
          <InputDatePicker
            label={'Date to'}
            id={'pdyearTo'}
            name={'pdyearTo'}
            value={values.pdyearTo}
            callback={updateValues}
          />
        </div>
      </Columns>
      <Columns className=''>
        <div className='column is-full is-offset-2 has-heading'>
          <Heading renderAs={'h2'} size={5}>
            Taxonomy
          </Heading>
        </div>
        <div className='column is-one-third is-offset-2'>
          <SelectElement
            name={'taxonomy'}
            values={[
              { value: '', text: 'Select' },
              { value: 'IPC', text: 'IPC' },
              { value: 'CPC', text: 'CPC' },
              { value: 'MeSH', text: 'MeSH' },
            ]}
            callback={updateValues}
          />
        </div>
        <div className='column is-one-third'>
          <InputText
            id={'taxonomyText'}
            name={'taxonomyText'}
            value={values.taxonomyText}
            placeholder={'diagnostic OR diagnosis'}
            callback={updateValues}
          />
        </div>
      </Columns>
      <Columns className=''>
        <div className='column is-full is-offset-2 has-heading'>
          <Heading renderAs={'h2'} size={5}>
            Keywords
          </Heading>
        </div>
        <div className='column is-one-third is-offset-2'>
          <SelectElement
            name={'kwic'}
            values={[
              { value: '', text: 'Select' },
              { value: 'WIPO catchwords', text: 'WIPO catchwords' },
              { value: 'Subject heading', text: 'Subject heading' },
            ]}
            value={values.kwic}
            callback={updateValues}
          />
        </div>
        <div className='column is-one-third'>
          <InputText
            id={'kwicText'}
            name={'kwicText'}
            value={values.kwicText}
            placeholder={'"PICO patients with pneumonia" OR symptoms'}
            callback={updateValues}
          />
        </div>
      </Columns>
      <Columns className=''>
        <div className='column is-full is-offset-2 has-heading'>
          <Heading renderAs={'h2'} size={5}>
            Title
          </Heading>
        </div>
        <div className='column is-two-thirds is-offset-2'>
          <InputText
            id={'title'}
            name={'title'}
            value={values.title}
            placeholder={'Fungal infections'}
            callback={updateValues}
          />
        </div>
      </Columns>
      <Columns className=''>
        <div className='column is-full is-offset-2 has-heading'>
          <Heading renderAs={'h2'} size={5}>
            Text*
          </Heading>
        </div>
        <div className='column is-two-thirds is-offset-2'>
          {!userDefinedSearch && (
            <TextAreaElement
              name={'query'}
              value={values.query}
              placeholder={
                'Fungal infections in combination with the patient s immunologic status allows narrowing of the differential diagnosis.'
              }
              callback={updateValues}
            />
          )}
          {userDefinedSearch && (
            <TextAreaElement
              name={'query'}
              value={values.query}
              placeholder={'some text OR some text AND some text'}
              callback={updateValues}
            />
          )}
          <input type='hidden' name='userDefined' value='false' />
          <InputCheckbox
            id={'userDefined'}
            name={'userDefined'}
            value={true}
            label={
              'Boolean search (To learn more about boolean search read our White Paper)'
            }
            onChange={booleanChange}
          />
        </div>
      </Columns>
      <Columns className=''>
        <div className='column is-offset-5'>
          <div className='buttons'>
            {/* <Button size={'medium'} onClick={clear}>
              Clear
            </Button> */}
            <Button
              color={'info'}
              size={'medium'}
              onClick={submit}
              loading={loading}
            >
              Paragraph Search
            </Button>
          </div>
        </div>
      </Columns>
    </form>
  );
}

export default withRouter(SearchForm);
